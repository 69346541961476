import React from 'react';
import Image from 'next/image';
import { Fade } from 'react-awesome-reveal';

type CompositionType = {
  key: number;
  title: string;
  description: string;
};

type CompositionPropsType = {
  composition: CompositionType;
};

export const Composition: React.FC<CompositionPropsType> = ({ composition }) => {
  return (
    <article className="flex items-center w-1/2 laptop:w-1/3 h-composition-mobile laptop:h-composition-laptop">
      <Fade direction="up" className="h-full w-full" delay={300 + 100 * composition.key}>
        <div className="flex shadow-md h-full items-center w-full px-2">
          <div className="flex-1 flex justify-start items-center pr-3">
            <Image
              src={`/composition_icon${composition.key}.jpg`}
              alt={`세금포인트 할인쇼핑몰 구성 ${composition.key}번 아이콘`}
              width="103"
              height="103"
            />
          </div>
          <div className="flex-2">
            <h2 className="mb-1 text-sm laptop:text-xl font-bold">{composition.title}</h2>
            <p className="laptop:text-base text-xs" style={{ wordBreak: 'keep-all' }}>
              {composition.description}
            </p>
          </div>
        </div>
      </Fade>
    </article>
  );
};
