import * as React from 'react';
import styles from '@styles/Header.module.css';

type Props = {
  detailNavStatus: boolean;
  detailNavHandler: Function;
  mobileButtonHandle: Function;
};

export const DetailNavButton = (props: Props) => {
  return (
    <div className="flex flex-1 justify-end">
      <button
        className={`block laptop:hidden ${styles.menu}`}
        style={{ outline: 'none' }}
        type="button"
        aria-label="Mobile Nav Button"
        onClick={() => props.mobileButtonHandle()}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16m-7 6h7"
          />
        </svg>
      </button>
      <button
        className={`hidden laptop:block ${styles.menu}`}
        style={{ outline: 'none' }}
        aria-label="PC Nav Button"
        type="button"
        onClick={() => props.detailNavHandler(props.detailNavStatus)}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16m-7 6h7"
          />
        </svg>
      </button>
    </div>
  );
};
