import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

export const Footer = () => (
  <footer
    id="taxmallFoot"
    className="flex flex-col justify-center w-full laptop:h-footer h-footer-mobile"
  >
    <div className="flex justify-center laptop:px-0 px-2 tablet:px-4 py-3 w-full laptop:h-footer-top h-footer-top-mobile text-white bg-footer-top">
      <div className="flex flex-col laptop:flex-row laptop:items-center items-start justify-center laptop:justify-start w-full max-w-screen-laptop text-sm">
        <span className="block laptop:hidden mb-1 tablet:text-base text-sm font-bold">
          세금포인트 할인쇼핑몰
        </span>
        <Link href="/customer">
          <span className="laptop:hover:text-blue-600 mr-0 laptop:mr-6 text-footer-text hover:text-white laptop:text-base tablet:text-sm text-xs cursor-default laptop:cursor-pointer">
            찾아 오시는 길
          </span>
        </Link>
        <Link href="/about/faq">
          <span className="laptop:hover:text-blue-600 text-footer-text hover:text-white laptop:text-base tablet:text-sm text-xs cursor-default laptop:cursor-pointer">
            자주 묻는 질문
          </span>
        </Link>
      </div>
    </div>
    <div className="flex justify-center laptop:px-0 px-2 tablet:px-4 py-3 w-full h-footer-bottom text-white bg-footer-bottom">
      <div className="flex flex-col laptop:flex-row laptop:justify-between justify-start w-full max-w-screen-laptop laptop:text-base text-xs">
        <div className="laptop:flex hidden laptop:flex-1 flex-none items-center">
          <Image
            src={`/logo_footer.png`}
            alt="세금포인트몰"
            width="174"
            height="33"
          />
        </div>
        <div className="flex laptop:flex-3 flex-none flex-col laptop:flex-row laptop:items-center items-start laptop:justify-between justify-start">
          <div className="laptop:flex-1 flex-none order-1 laptop:order-2 text-footer-text">
            <h3>대표전화/고객센터</h3>
            <span className="laptop:text-3xl text-xl">1522-6009</span>
          </div>
          <div className="flex laptop:flex-4 flex-none flex-col laptop:flex-row flex-no-wrap laptop:flex-wrap justify-start laptop:order-1 order-2">
            <span className="text-footer-text text-xxs laptop:text-sm">
              서울특별시 양천구 목동동로 309(행복한백화점) 중소기업유통센터
            </span>
            <span className="text-footer-text text-xxs laptop:text-sm">
              대표이사 : 이태식 | 개인정보보호책임자 : 이성복
            </span>
            <span className="text-footer-text text-xxs laptop:text-sm">
            사업자등록번호 : 107-81-53660 | 통신판매업신고 : 제2016-서울양천-00030호
            </span>
            <span className="mr-0 laptop:mr-1 text-footer-text text-xxs laptop:text-sm">
             COPYRIGHT © 2023 SMALL & MEDIUM BUSINESS DISTRIBUTION CENTER.
            </span>
            <span className="text-footer-text text-xxs laptop:text-sm">ALL RIGHTS RESERVED.</span>
          </div>
        </div>
      </div>
    </div>
  </footer>
);
