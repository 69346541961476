import React, { ReactNode } from 'react';
import { Breadcrumb, ContentsHeader } from '@components';

type IContents = {
  title1: string;
  title2: string;
  contentsHeaderTitle: string;
  contentsHeaderDesc: string;
  border?: boolean;
};

type IContentsProps = {
  contents: IContents;
  children: ReactNode;
};

export const ContentsLayout = (props: IContentsProps) => {
  return (
    <div className="flex flex-col laptop:pt-header-laptop pt-header-mobile w-full max-w-screen-laptop h-full">
      <Breadcrumb title1={props.contents.title1} title2={props.contents.title2} />
      <ContentsHeader
        title={props.contents.contentsHeaderTitle}
        description={props.contents.contentsHeaderDesc}
        border={props.contents.border}
      />
      <div className="laptop:pb-10 pb-5 laptop:px-0 px-2 tablet:px-4">{props.children}</div>
    </div>
  );
};
