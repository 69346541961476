import React, { useState } from 'react';

import styles from '@styles/Header.module.css';

import SimpleNavItem from '@components/header/SimpleNavItem';
import DetailNav from '@components/header/DetailNav';
import Logo from '@components/header/Logo';
import { DetailNavButton } from '@components/header/DetatilNavButton';
import MobileNav from '@components/header/MobileNav';

type HeaderPropsType = {
  scrollY: number;
};

export const Header: React.FC<HeaderPropsType> = ({ scrollY }) => {
  const [detailNavStatus, setDetailNavStatus] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false);

  const detailNavClose = () => {
    setDetailNavStatus(false);
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  };

  const detailNavHandler = (detailNavStatus: boolean) => {
    setDetailNavStatus(!detailNavStatus);

    if (detailNavStatus) {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    }
  };

  const mobileButtonHandle = () => {
    setShowMobileNav(!showMobileNav);
    if (showMobileNav) {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    }
  };

  return (
    <>
      <header
        id="taxmallHead"
        className={`w-full flex justify-center h-mobile-nav px-2 tablet:px-4 laptop:px-0 border-b border-content-border laptop:border-b-0
        ${styles.head_taxmall} ${scrollY <= 0 ? `${styles.head_taxmall_isTop}` : 'shadow-md'}`}
      >
        <nav className="relative flex justify-between py-0 w-full max-w-screen-laptop lg:py-3">
          <Logo />
          <div className="text-re flex-1" />
          <div className="flex flex-4 items-center">
            <SimpleNavItem />
            <DetailNavButton
              detailNavStatus={detailNavStatus}
              detailNavHandler={detailNavHandler}
              mobileButtonHandle={mobileButtonHandle}
            />
          </div>
        </nav>
        <MobileNav isMobileNavShow={showMobileNav} mobileButtonHandle={mobileButtonHandle} />
      </header>
      <DetailNav detailNavStatus={detailNavStatus} detailNavClose={detailNavClose} />
    </>
  );
};
