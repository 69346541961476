import React from 'react';
import Link from 'next/link';

type IBreadcrumbProps = {
  title1: string;
  title2: string;
};

export const Breadcrumb = (props: IBreadcrumbProps) => {
  return (
    <div className="hidden laptop:flex items-center mt-3 laptop:px-0 px-2 py-2 laptop:h-bread-crumb h-bread-crumb-mobile laptop:text-sm text-xs select-none">
      <Link href="/">
        <a className="cursor-default laptop:cursor-pointer">
          <svg
            className="mr-0 laptop:mr-2 w-4 h-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
            />
          </svg>
        </a>
      </Link>
      <svg
        className="mr-0 laptop:mr-2 w-4 h-4"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M9 5l7 7-7 7" />
      </svg>
      <span className="mr-0 laptop:mr-2">{props.title1}</span>
      <svg
        className="mr-0 laptop:mr-2 w-4 h-4"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M9 5l7 7-7 7" />
      </svg>
      <span className="text-primary font-bold">{props.title2}</span>
    </div>
  );
};
