import data from '@public/meta.json';
import React from 'react';
import { Usage } from '@components';

export const Usages: React.FC = () => {
  return (
    <div className="flex flex-col items-center laptop:pt-10 py-5 w-full max-w-screen-laptop">
      <div className="flex flex-col laptop:px-0 px-2 tablet:px-4 w-full">
        <div className="border-b-1 inline-block border-black">
          <h1 className="table pb-2 pl-0 pt-3 tablet:text-2xl text-base font-bold border-b-2 border-black">
            세금포인트 할인쇼핑몰 이용방법
          </h1>
        </div>
        <div className="flex flex-col laptop:flex-row pt-3 laptop:py-8 laptop:h-usage-container border-t border-black">
          {(data?.usage ?? []).map((usage) => (
            <Usage key={usage.title} usage={usage} />
          ))}
        </div>
      </div>
    </div>
  );
};
