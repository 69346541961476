import React from 'react';

export const IntroText: React.FC = () => {
  return (
    <>
      <li>
        세금 납부에 대한 보람과 자긍심을 고취하기 위해 개인 또는 법인(중소기업)이 납무한
        세금(소득세·법인세)에 따라 포인트를 부여하고, 세금포인트를 사용하여 다양한 헤택을 받을 수
        있는 제도입니다.
      </li>
      <li className="text-footer-text-sub mt-3 laptop:mt-6">
        세금포인트 할인쇼핑몰은 세금포인트 조회를 위해 반드시 국세청 홈택스에 로그인 후 이용
        가능함을 알려드립니다.
      </li>
    </>
  );
};
