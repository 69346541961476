import React, { forwardRef, Ref } from 'react';

type KakaoMapComponentProps = {
  ref: Ref<HTMLDivElement>;
};
export const KakaoMap: React.FC<KakaoMapComponentProps> = forwardRef((_props, ref) => {
  return (
    <div className="mt-8 w-full h-detail-nav-active">
      <div ref={ref} className="w-full h-full" />
    </div>
  );
});
