import React from 'react';

type LinkBoxType = {
  text: string;
  iconLabel: string;
};

type LinkBoxProps = {
  linkBox: LinkBoxType;
};

export const LinkBox: React.FC<LinkBoxProps> = ({ linkBox }) => {
  return (
    <div className="flex items-center justify-center mt-3 laptop:mt-10 p-2 laptop:p-5 laptop:text-base text-xs bg-gray-custom">
      <div className="flex flex-col laptop:flex-row items-center justify-center py-2 laptop:py-5 w-full bg-white">
        <span className="mr-0 laptop:mr-8 font-medium">{linkBox.text}</span>
        <a
          rel='noopener noreferrer'
          className="flex items-center text-primary font-bold cursor-default laptop:cursor-pointer"
          href="https://fanfandaero.kr/"
          target="_blank"
        >
          <span className="flex items-center mr-2">{linkBox.iconLabel}</span>
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
            />
          </svg>
        </a>
      </div>
    </div>
  );
};
