import React from 'react';

import { NextSeo } from 'next-seo';
import Head from 'next/head';

import { Config } from '../../utils/Config';

type IMetaProps = {
  title: string;
  description: string;
  canonical?: string;
};

export const Meta = (props: IMetaProps) => (
  <>
    <Head>
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,minimum-scale=1.0,maximum-scale=1"
        key="viewport"
      />
        <meta
            name="keywords"
            content="세금포인트몰, 세금포인트 할인쇼핑몰, 세금포인트할인쇼핑몰, 세금포인트쇼핑몰, 세금포인트 쇼핑몰, 동반성장몰, 중소기업유통센터, 홈택스, 국세청"
        />
      <link
        rel="apple-touch-icon"
        href={`/apple-touch-icon.png`}
        key="apple"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`/favicon-32x32.png`}
        key="icon32"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`/favicon-16x16.png`}
        key="icon16"
      />
      <link rel="icon" href={`/favicon.ico`} key="favicon" />
    </Head>
    <NextSeo
      title={props.title}
      description={props.description}
      canonical={props.canonical}
      openGraph={{
        title: props.title,
        description: props.description,
        url: props.canonical,
        locale: Config.locale,
        site_name: Config.site_name,
        images : [
          {
            url: 'https://www.sbdc.or.kr/assets/dongban/taxpoint-og.png',
            width: 158,
            height: 59,
            alt: '세금포인트 할인쇼핑몰 로고',
          },
        ]
      }}
    />
  </>
);
