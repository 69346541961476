import React from 'react';
import data from '@public/meta.json';
import { Example } from '@components';

export const Examples: React.FC = () => {
  return (
    <div>
      {(data?.examples ?? []).map((example) => (
        <Example key={example.key} example={example} />
      ))}
    </div>
  );
};
