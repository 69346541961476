import data from '@public/meta.json';
import React from 'react';
import { Composition } from '@components';
import Image from 'next/image';
import { Fade } from 'react-awesome-reveal';

export const Compositions: React.FC = () => {
  return (
    <div className="flex flex-col laptop:mt-10 mt-5">
      <div className="w-full h-full">
        <Fade triggerOnce>
          <div className="laptop:block hidden">
            <Image
              src={`/composition.jpg`}
              alt="세금포인트몰"
              width="1100"
              height="319"
            />
          </div>
          <div className="block laptop:hidden">
            <Image
              src={`/composition_mobile.jpg`}
              alt="세금포인트몰"
              width="720"
              height="320"
            />
          </div>
        </Fade>
      </div>
      <div className="flex flex-wrap w-full">
        {(data?.compositions ?? []).map((composition) => (
          <Composition key={composition.key} composition={composition} />
        ))}
      </div>
    </div>
  );
};
