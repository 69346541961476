import React, { CSSProperties } from 'react';

type ScrollTopProps = {
  y: number;
};

const buttonStyle: CSSProperties = {
  width: 70,
  height: 70,
  bottom: 50,
  borderRadius: 35,
  left: 'calc(100% - 100px)',
  outline: 'none',
  opacity: 1,
  transition: 'all 0.4s',
  boxShadow: '0 5px 26px 0 rgb(0 0 0 / 30%)',
  textTransform: 'uppercase',
};

const handleScrollTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const ScrollTop: React.FC<ScrollTopProps> = ({ y }) => {
  return (
    <button
      className="fixed shadow-md bg-primary text-white flex flex-col justify-center items-center"
      style={y > 10 ? buttonStyle : { ...buttonStyle, opacity: 0, zIndex: -10 }}
      onClick={() => handleScrollTop()}
    >
      <svg
        className="w-6 h-6"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M9 11l3-3m0 0l3 3m-3-3v8m0-13a9 9 0 110 18 9 9 0 010-18z"
        />
      </svg>
      <span>Top</span>
    </button>
  );
};
