import React, { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';

import styles from '@styles/Header.module.css';
import data from '@public/meta.json';

type MobileNavProps = {
  isMobileNavShow: boolean;
  mobileButtonHandle: any;
};
const MobileNav = (props: MobileNavProps) => {
  const initArr = (data?.menu ?? []).filter((x) => x.key === 1);
  const [activeMenu, setActiveMenu] = useState([...initArr[0].items]);
  const [activeParentUrl, setActiveParentUrl] = useState('/intro');
  const activateMenu = (menuKey: number) => {
    const activeArr = (data?.menu ?? []).filter((x) => x.key === menuKey);
    setActiveMenu(activeArr[0].items);
    setActiveParentUrl(activeArr[0].path);
  };

  return (
      <div
      className={`${
        props.isMobileNavShow ? styles.mobileFullContainerActive : ''
      } laptop:hidden flex-col w-full bg-white ${styles.taxmallMobileNav}`}
    >
      <div className="flex items-center justify-between laptop:px-0 px-2 tablet:px-4 h-mobile-nav text-white bg-primary">
        <Image
          src={`/logo_mobile_nav.png`}
          alt="세금포인트몰"
          width="230"
          height="40"
        />
        <button
          aria-label="Mobile Nav Close Button"
          style={{ outline: 'none' }}
          onClick={props.mobileButtonHandle}
        >
          <svg
            className="w-8 h-8"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div className="flex tablet:text-base text-sm" style={{ height: 'calc(100vh - 60px)' }}>
        <div className={`font-bold ${styles.mobileNavLeft} ${styles.mobileNavCommon}`}>
          <ul>
            <li className="text-base cursor-pointer" onClick={() => activateMenu(1)}>
              세금포인트몰 소개
            </li>
            <li className="text-base cursor-pointer" onClick={() => activateMenu(2)}>
              입점안내
            </li>
            <li className="text-base cursor-pointer" onClick={() => activateMenu(3)}>
              <Link href="/customer">
                <span>고객센터</span>
              </Link>
            </li>
            <li className="text-base cursor-pointer" onClick={() => activateMenu(4)}>
              <a href="https://www.sbdc.or.kr" target="_blank" rel="noopener noreferrer">
                기관소개
              </a>
            </li>
          </ul>
        </div>
        <div className={`px-2 laptop:px-0 bg-white ${styles.mobileNavCommon}`}>
          <ul>
            {activeMenu.map((x: any) => (
              <Link key={x.key} href={`${activeParentUrl}${x.path}`}>
                <li className="border-b border-content-border font-medium">
                  <span className="text-black border-none cursor-pointer">{x.name}</span>
                </li>
              </Link>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MobileNav;
