import React from 'react';

import styles from '@styles/ContentsHeader.module.css';

type IContentsHeaderProps = {
  title: string;
  description: string;
  border?: boolean;
};

export const ContentsHeader = (props: IContentsHeaderProps) => {
  return (
    <div
      className={`flex flex-col items-center laptop:pt-8 laptop:px-0 px-2 tablet:px-4 text-center ${
        props.border ? 'border-b pt-10 pb-5 laptop:py-5 laptop:pb-20' : 'pt-10 pb-0 laptop:pt-0'
      } border-black`}
    >
      <h2 className="pb-0 laptop:pb-3 laptop:text-2xl laptop:text-contents-header-title text-xl font-medium">
        {props.title}
      </h2>
      <p
        style={{ wordBreak: 'keep-all' }}
        className={`${styles.description} text-sm laptop:text-base pt-2 tablet:pt-4 leading-6`}
        dangerouslySetInnerHTML={{ __html: props.description }}
      />
    </div>
  );
};
