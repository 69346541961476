import React from 'react';
import { Fade } from 'react-awesome-reveal';

type SloganType = {
  title_en: string;
  title_ko: string;
};

export const Slogan: React.FC<SloganType> = ({ title_en, title_ko }) => {
  return (
    <div className="flex flex-1 flex-col justify-center">
      <Fade className="flex justify-center" cascade direction="up" triggerOnce>
        <span className="text-center tablet:text-lg text-sm font-bold">{title_en}</span>
        <span className="text-center tablet:text-base text-xs">{title_ko}</span>
      </Fade>
    </div>
  );
};
