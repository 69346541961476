import data from '@public/meta.json';
import React from 'react';
import { Slogan } from '@components';

export const Slogans: React.FC = () => {
  return (
    <div className="w-full flex justify-center px-2 laptop:px-0 bg-gray-custom-500">
      <div
        className="w-full laptop:max-w-screen-laptop flex justify-between py-4
                   divide-primary divide-x"
      >
        {(data?.slogans ?? []).map((x) => (
          <Slogan key={x.title_en} title_en={x.title_en} title_ko={x.title_ko} />
        ))}
      </div>
    </div>
  );
};
