import React from 'react';

export const IntroTable: React.FC = () => {
  return (
    <table className="w-full text-center tablet:text-base text-xxs border-t-2 border-black">
      <thead>
        <tr className="bg-gray-custom border-b border-primary">
          <th className="py-1 tablet:py-3">구매금액</th>
          <th>10만원이하</th>
          <th>10~20만원</th>
          <th>20~30만원</th>
          <th>30~40만원</th>
          <th>40만원초과</th>
        </tr>
      </thead>
      <tbody>
        <tr className="border-b border-primary">
          <td className="py-1 tablet:py-3">사용포인트</td>
          <td>1P</td>
          <td>2P</td>
          <td>3P</td>
          <td>4P</td>
          <td>5P</td>
        </tr>
        <tr className="border-b border-primary">
          <td className="py-1 tablet:py-3">할인율</td>
          <td>5%</td>
          <td>5%</td>
          <td>5%</td>
          <td>5%</td>
          <td>5%</td>
        </tr>
      </tbody>
    </table>
  );
};
