import React from 'react';
import styles from '@styles/Index.module.css';
import { ChevronBottom } from '@components/icons/ChevronBottom';
import { ChevronRight } from '@components/icons/ChevronRight';
import Image from 'next/image';
import { Fade } from 'react-awesome-reveal';

type UsagePropsType = {
  usage: UsageType;
};

type UsageType = {
  title: string;
  path: string;
  description1: string;
  description2: string;
  time: number;
};

export const Usage: React.FC<UsagePropsType> = ({ usage }) => {
  return (
    <div
      key={usage.title}
      className={`w-full laptop:w-1/4 flex-row laptop:flex-col items-center laptop:justify-center last:border-0 laptop:border-b-0
          laptop:border-r border-primary relative flex justify-around 
          text-center border-b border-r-0 ${styles.tt} group`}
    >
      <Fade triggerOnce delay={300 + usage.time * 300}>
        <div className="flex flex-1 laptop:flex-none justify-center mb-2">
          <Image
            className="laptop:transform laptop:group-hover:scale-110 laptop:transition laptop:duration-300"
            src={`/${usage.path}`}
            alt={usage.path}
            width="143"
            height="124"
          />
        </div>
        <div className="flex flex-1 laptop:flex-none flex-col">
          <span className="text-base tablet:text-lg font-bold">{usage.title}</span>
          {usage.path !== 'icon4.png' && (
            <span className="mt-2 tablet:text-base text-sm">{usage.description1}</span>
          )}
          {usage.path === 'icon4.png' && (
            <span className="mb-2 text-base tablet:text-lg font-bold">{usage.description1}</span>
          )}
          <span className="tablet:text-base text-sm">{usage.description2}</span>
        </div>
        <div
          className={`absolute flex laptop:hidden bg-primary justify-center items-center 
            text-white ${styles.btn}`}
        >
          <ChevronBottom />
        </div>
        <div
          className={`absolute hidden laptop:flex bg-primary justify-center items-center 
            text-white ${styles.btn}`}
        >
          <ChevronRight />
        </div>
      </Fade>
    </div>
  );
};
