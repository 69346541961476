import React from 'react';

type ExampleProps = {
  example: ExampleType;
};

type ExampleType = {
  key: number;
  order: number;
  point: number;
  discount: string;
  background: string;
};

export const Example: React.FC<ExampleProps> = ({ example }) => {
  return (
    <div className="flex flex-col laptop:flex-row mb-0 laptop:mb-2">
      <div
        className="flex laptop:flex-1 items-center justify-between px-2 py-1 text-white"
        style={{
          background: example.background,
        }}
      >
        <div className="flex flex-1 items-center justify-center w-5 h-5 text-black bg-white rounded-xl">
          <span>{example.key}</span>
        </div>
        <span className="flex-9 text-center">
          구매금액이 <strong>{example.order}</strong>만원인 경우
        </span>
      </div>
      <div className="laptop:flex-2 p-3 text-center bg-gray-custom">
        세금포인트 <strong>{example.point}p</strong>를 사용하면 결제금액에서{' '}
        <strong>{example.discount}</strong>원을 할인
      </div>
    </div>
  );
};
