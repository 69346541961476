import { useEffect, useState } from 'react';

export const useScroll = () => {
  const [state, setState] = useState({
    y: 0,
  });
  const onScroll = () => {
    setState({ y: window.pageYOffset });
  };
  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll); // clean up
  }, []);
  return state;
};
