import React from 'react';
import data from '@public/meta.json';
import Link from 'next/link';

const SimpleNavItem = () => {
  return (
    <div className="laptop:flex hidden justify-between" style={{ flex: 8 }}>
      {(data?.menu ?? []).map((x) =>
        x.external ? (
          <a
            key={x.key}
            href={x.path}
            target="_blank"
            className="text-black text-xl hover:font-bold font-medium border-none cursor-pointer"
            rel="noopener noreferrer"
          >
            {x.title}
          </a>
        ) : (
          <Link key={x.key} href={x.path}>
            <a className="text-black text-xl hover:font-bold font-medium border-none cursor-pointer">
              {x.title}
            </a>
          </Link>
        )
      )}
    </div>
  );
};

export default SimpleNavItem;
