import React, { ReactNode, useEffect } from 'react';
import { Header, Footer } from '@layout';
import { useScroll } from '../../utils/hooks';
import { ScrollTop } from '@components';

type IMainProps = {
  meta: ReactNode;
  children: ReactNode;
};

export const Main = (props: IMainProps) => {
  const { y } = useScroll();
  useEffect(() => {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  });

  return (
    <>
      <div
        id="taxmallWrap"
        className="flex flex-col w-full min-h-screen antialiased bg-white outline-none"
      >
        {/* Meta */}
        {props.meta}
        {/* Herader */}
        <Header scrollY={y} />

        {/* Main */}
        <main id="taxmallContent" className="flex flex-col items-center w-full text-base">
          {props.children}
        </main>

        {/* Footer */}
        <Footer />
      </div>
      <ScrollTop y={y} />
    </>
  );
};
