import React from 'react';
import Link from 'next/link';
import Image from 'next/image';

const Logo = () => {
  return (
    <div className="flex items-center flex-none laptop:flex-1">
      <Link href="/">
        <span className="flex-none laptop:block hidden laptop:flex-1 cursor-pointer">
          <Image
            src={`/logo 151x52.png`}
            alt="세금포인트몰 PC 로고"
            width="151"
            height="52"
          />
        </span>
      </Link>
      <Link href="/">
        <span className="block laptop:hidden cursor-pointer">
          <img
            alt="세금포인트몰 모바일 로고"
            style={{ width: 230, height: 40 }}
            src={`/logo_mobile.svg`}
          />
        </span>
      </Link>
    </div>
  );
};

export default Logo;
