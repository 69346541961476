import React from 'react';

import styles from '@styles/MainBanner.module.css';
import { Fade, Zoom } from 'react-awesome-reveal';

export const MainBanner = () => (
  <div className={`w-full relative px-2 tablet:px-4 py-3 laptop:p-0 ${styles.mainImgContainer}`}>
    <div className="flex flex-col items-end laptop:justify-center justify-start w-full max-w-screen-laptop laptop:leading-relaxed leading-tight">
      <Fade triggerOnce>
        <h2 className="mb-5 mt-24 laptop:mt-10 laptop:text-3xl text-xl font-bold">
          세금포인트 할인쇼핑몰
        </h2>
        <span className="tablet:text-base laptop:text-lg text-sm">
          납세자가 보유하고 있는 <strong>세금 포인트</strong>를 사용하여
        </span>
        <span className="tablet:text-base laptop:text-lg text-sm">
          <strong>중소기업 제품을 할인</strong>받아 구매할 수 있는
        </span>
        <span className={`tablet:text-base text-sm laptop:text-lg ${styles.halfBg}`}>
          <strong>세금포인트 사용 전용 온라인쇼핑몰</strong>
        </span>
      </Fade>
      <a
        rel="noopener noreferrer"
        className={`${styles.linkWrapper} text-center transform flex text-white hover:scale-100 
        laptop:hover:scale-110 transition duration-500 focus:outline-none flex items-center laptop:text-lg text-sm cursor-pointer`}
        href="https://www.hometax.go.kr/websquare/websquare.wq?w2xPath=/ui/pp/index_pp.xml&tmIdx=01&tm2lIdx=0116000000&tm3lIdx=0116030000"
        target="_blank"
      >
        <strong className="mr-1">PC</strong> 바로가기
      </a>
      <a
          rel="noopener noreferrer"
          className={`${styles.linkWrapper} text-center transform flex text-white hover:scale-100 
        laptop:hover:scale-110 transition duration-500 focus:outline-none flex items-center laptop:text-lg text-sm cursor-pointer`}
          href="https://hometax.page.link/?apn=kr.go.nts.android&isi=495157796&ibi=kr.go.nts.taxtotal&efr=1&link=https://hometax.go.kr?action=https://mob.tbht.hometax.go.kr/jsonAction.do?actionId=UTBSFBBE04F001&menuId=6001180300"
          target="_blank"
      >
        <strong className="mr-1">모바일</strong> 바로가기<span style={{fontSize : 12}}>(손택스 앱 설치 필요)</span>
      </a>
      <Zoom triggerOnce delay={700}>
        <div className="mt-2 laptop:mt-5 border-b border-black">
          <span className="tablet:text-lg text-sm">
            <strong>홈택스 로그인 후</strong> 세금포인트몰 접속 가능
          </span>
        </div>
      </Zoom>
    </div>
  </div>
);
