import React, { ReactNode } from 'react';
type ContentBoxProps = {
  title: string;
  children: ReactNode;
};

export const ContentBox: React.FC<ContentBoxProps> = ({ title, children }) => {
  return (
    <div className="flex flex-col laptop:flex-row py-4 laptop:py-8 border-b border-content-border min-h-content-box justify-center">
      <div
        style={{ wordBreak: 'keep-all' }}
        className="laptop:flex-1 laptop:pb-0 pb-3 px-0 laptop:px-3 break-words text-base tablet:text-lg laptop:text-xl font-bold"
      >
        {title}
      </div>
      <div className="laptop:flex-4 tablet:text-base text-sm">
        <ul className="leading-6 laptop:leading-7 list-outside">{children}</ul>
      </div>
    </div>
  );
};
