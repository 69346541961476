import React from 'react';
import styles from '@styles/Header.module.css';
import data from '@public/meta.json';
import Link from 'next/link';

type DetailNavProps = {
  detailNavStatus: boolean;
  detailNavClose: Function;
};

const DetailNav = ({ detailNavStatus, detailNavClose }: DetailNavProps) => {
  return (
    <div
      className={`w-full hidden laptop:flex flex-col items-center select-none ${styles.test} ${
        detailNavStatus ? styles.testActive : ''
      }`}
    >
      <div className="flex justify-center py-8 w-full bg-detail-nav-bg">
        <div className="flex justify-between w-full max-w-screen-laptop" style={{ zIndex: 11 }}>
          {(data?.menu ?? []).map((x: any) => (
            <div key={x.key} className={`flex flex-col ${styles.test2}`}>
              {x.external ? (
                <a
                  key={x.key}
                  href={x.path}
                  target="_blank"
                  className={`p-3 text-center text-lg font-bold bg-white border border-solid border-primary-blue cursor-pointer ${styles.title}`}
                >
                  {x.title}
                </a>
              ) : (
                <Link key={x.key} href={x.path}>
                  <span
                    className={`p-3 text-center text-lg font-bold bg-white border border-solid border-primary-blue cursor-pointer ${styles.title}`}
                  >
                    {x.title}
                  </span>
                </Link>
              )}
              <ul>
                {x.items.map((y: any) => (
                  <Link key={y.key} href={`${x.path}${y.path}`}>
                    <li className="group flex text-base border-b border-primary cursor-default laptop:cursor-pointer">
                      <span className="p-3 w-full h-full text-black group-hover:text-white group-hover:bg-primary border-none transition duration-500 ease-in-out">
                        {y.name}
                      </span>
                    </li>
                  </Link>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <div
        onClick={() => detailNavClose()}
        className={`py-8 w-full ${detailNavStatus ? 'h-detail-nav-active' : 'h-detail-nav'}`}
      />
    </div>
  );
};

export default DetailNav;
